import { useDataUtilsSingleton } from '@/common/utils/dataUtilsSingleton';
import {
  C360Account,
  C360AccountsBusinessResponse,
} from '../../store/modules/getC360AcctsParallelModule/types';
import * as st from '../strings';

class VuelidateRulesSingleton {
  isSAMAcctNumRegexMatch = (num: string) =>
    st.REGEXP_LAST4.test(num) ||
    st.REGEXP_BAN.test(num) ||
    st.REGEXP_BTN_OPTIONAL_CC.test(num) ||
    st.REGEXP_BAID.test(num) ||
    st.REGEXP_BAID_SMALL.test(num) ||
    st.REGEXP_EMAIL.test(num);

  isSAMAcctNumBasicRegexMatch = (num: string) =>
    st.REGEXP_LAST4.test(num) ||
    st.REGEXP_BAN.test(num) ||
    st.REGEXP_BTN_OPTIONAL_CC.test(num) ||
    st.REGEXP_BAID.test(num) ||
    st.REGEXP_BAID_SMALL.test(num);

  isEmailBasicRegexMatch = (email: string) => st.REGEXP_EMAIL.test(email);

  isPhoneNumberBasicRegexMatch = (phone: string) => st.REGEXP_PHONE.test(phone);

  isSAMAcctNumInvalidRegexMatch = (num: string) => {
    const isBanInvalid =
      num?.length <= 4
        ? st.REGEXP_BAN_TRUNCATED_INAVLID.test(num)
        : st.REGEXP_BAN_INAVLID.test(num);
    return st.REGEXP_EMAIL_INVALID.test(num) || isBanInvalid;
  };
  // isSAMAcctEmailRegexMatch = (num: string) => st.REGEXP_EMAIL.test(num);

  private isNoAcctsFoundErrResp = (resp: C360AccountsBusinessResponse) =>
    resp?.c360AccountList?.accounts?.length === 0;

  isNoAcctsFoundErr = (c360AcctListErr: any, num: string) =>
    c360AcctListErr?.accountNumber === num &&
    this.isNoAcctsFoundErrResp(c360AcctListErr?.err?.data);

  isNoAcctsFoundError = (resp: C360AccountsBusinessResponse) =>
    this.isNoAcctsFoundErrResp(resp);

  private isAcctDeactivatedErrResp = (resp: C360AccountsBusinessResponse) =>
    resp?.c360AccountList?.accounts &&
    resp.c360AccountList.accounts.length >= 1 &&
    resp.c360AccountList.accounts.filter((account: C360Account) =>
      (st.INACTIVE_STATUSES as any[]).includes(
        account?.accountStatus?.toLowerCase()
      )
    ).length === resp.c360AccountList.accounts.length;

  isAcctDeactivatedErr = (c360AcctListErr: any, num: string) =>
    c360AcctListErr?.accountNumber === num &&
    this.isAcctDeactivatedErrResp(c360AcctListErr?.err?.data);

  isAcctDeactivatedError = (resp: C360AccountsBusinessResponse) =>
    this.isAcctDeactivatedErrResp(resp);

  private isAcctSuspendedErrResp = (
    resp: C360AccountsBusinessResponse,
    num: string
  ) =>
    resp?.c360AccountList?.accounts &&
    resp.c360AccountList.accounts.length >= 1 &&
    st.SUSPENDED_STATUSES.map((x) => x.toLowerCase()).includes(
      resp.c360AccountList.accounts
        .find(
          (account: C360Account) =>
            account?.accountNumber === num ||
            account?.accountNumber?.replaceAll('*', '') ===
              num.substring(num.length - 4, num.length)
        )
        ?.accountStatus?.toLowerCase() || 'assumingNonSusp'
    );

  isAcctSuspendedError = (resp: C360AccountsBusinessResponse, num: string) =>
    this.isAcctSuspendedErrResp(resp, num);

  isSAMOrderNumRegexMatch = (num: string) =>
    st.REGEXP_ORDERNUMORTICKETNUM.test(num);

  isSAMStateValid = (state: string) =>
    st.USA_STATES_ABBREV.map((x) => x.toUpperCase()).includes(
      state?.toUpperCase()
    );

  isZipCodeRegexMatch = (zip: string) => st.REGEXP_ZIPCODE.test(zip);

  isAcctNumInC360AcctList = (
    resp: C360AccountsBusinessResponse,
    acctNum: string
  ) =>
    resp?.c360AccountList?.accounts?.find(
      (c360Account: C360Account) => c360Account?.accountNumber === acctNum
    ) !== undefined;

  hasAcctsWithoutBadStatuses = (resp: C360AccountsBusinessResponse) =>
    !resp ||
    !Object.values(resp).length ||
    useDataUtilsSingleton().getAcctsWithoutStatuses(
      resp?.c360AccountList?.accounts,
      [...st.INACTIVE_STATUSES, undefined]
    )?.length > 0;

  hasAcctsWithoutBadStatusesWithZip = (
    resp: C360AccountsBusinessResponse,
    zip: string
  ) =>
    !resp ||
    !Object.values(resp).length ||
    useDataUtilsSingleton().getAcctsWithoutStatusesWithZip(
      resp?.c360AccountList?.accounts,
      [...st.INACTIVE_STATUSES, undefined],
      zip
    )?.length > 0;

  isAcctOrPpb = (acctNum: string) =>
    /^\d+$/.test(acctNum) ||
    st.REGEXP_BAID.test(acctNum) ||
    st.REGEXP_BAID_SMALL.test(acctNum);
}

let vuelidateRulesSingleton: VuelidateRulesSingleton;

export const useVuelidateRulesSingleton = (): VuelidateRulesSingleton => {
  if (!vuelidateRulesSingleton) {
    vuelidateRulesSingleton = new VuelidateRulesSingleton();
  }
  return vuelidateRulesSingleton;
};
